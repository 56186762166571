import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.function.name.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { GET, PATCH, QUERY } from "@/core/services/store/request.module";
export default {
  mixins: [CommonMixin, ValidationMixin],
  data: function data() {
    return {
      payment: 0,
      pageLoading: false,
      particuleFieldLoading: false,
      paymentDatePicker: false,
      detail: {
        id: 0,
        invoice: {
          id: 0
        },
        payment_mode: {},
        documents: [],
        custom_field: []
      },
      customer: {},
      property: {},
      billing: {},
      property_contact_person: {},
      billing_contact_person: {},
      contact_person: {},
      notesAttachmentData: {},
      paymentModeList: [],
      moreActions: [],
      updateDetailDialog: {
        reference: false,
        payment_mode: false,
        payment_date: false,
        amount: false,
        transaction_id: false
      },
      updateDetail: {
        reference: null,
        payment_mode: null,
        payment_date: null,
        amount: null,
        transaction_id: null
      }
    };
  },
  methods: {
    updateMoreAction: function updateMoreAction(action) {
      var _this = this;

      switch (action) {
        case "mark_as_unpaid":
          _this.patchPayment({
            status: 2
          }).then(function () {
            _this.loadContent();
          }).catch(function (error) {
            _this.logError(error);
          });

          break;

        case "mark_as_paid":
          _this.patchPayment({
            status: 3
          }).then(function () {
            _this.loadContent();
          }).catch(function (error) {
            _this.logError(error);
          });

          break;

        case "download_pdf":
          window.open(process.env.VUE_APP_API_URL + "payment/" + _this.detail.id + "/pdf/download", "_blank");
          break;

        case "print":
          window.open(process.env.VUE_APP_API_URL + "payment/" + _this.detail.id + "/print", "_blank");
          break;
      }
    },
    updateField: function updateField(type) {
      var _this = this;

      if (_this.canUpdatePayment && _this.getPermission("payment:update")) {
        _this.onEscParticularField();

        _this.updateDetailDialog[type] = true;

        if (type == "payment_mode") {
          _this.updateDetail[type] = _this.detail[type].id;
        } else {
          _this.updateDetail[type] = _this.detail[type];
        }

        setTimeout(function () {
          _this.$refs[type].focus();
        }, 50);
      }
    },
    onEscParticularField: function onEscParticularField() {
      this.updateDetailDialog = {
        reference: false,
        payment_mode: false,
        payment_date: false,
        amount: false,
        transaction_id: false
      };
      this.updateDetail = {
        reference: null,
        payment_mode: null,
        payment_date: null,
        amount: null,
        transaction_id: null
      };
    },
    getAttributes: function getAttributes(attribute) {
      var _this = this;

      _this.$store.dispatch(QUERY, {
        url: "payment/options",
        data: {
          attribute: attribute
        }
      }).then(function (_ref) {
        var data = _ref.data;

        if (_this.lodash.isEmpty(data.payment_modes) === false) {
          _this.paymentModeList = data.payment_modes;
        }
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    updateParticularField: function updateParticularField() {
      var _this = this;

      _this.particuleFieldLoading = true;
      _this.paymentDatePicker = false;

      _this.patchPayment(_this.updateDetail).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.loadContent();

        _this.particuleFieldLoading = false;

        _this.onEscParticularField();
      });
    },
    getFormattedDate: function getFormattedDate(date) {
      return this.formatDate(date);
    },
    patchPayment: function patchPayment(data) {
      var _this = this;

      if (_this.canUpdatePayment && _this.getPermission("payment:update")) {
        return new Promise(function (resolve, reject) {
          _this.$store.dispatch(PATCH, {
            url: "payment/" + _this.detail.id,
            data: data
          }).then(function (_ref2) {
            var data = _ref2.data;
            resolve(data);
          }).catch(function (error) {
            reject(error);
          });
        });
      }
    },
    loadContent: function loadContent() {
      var _this = this;

      _this.getPayment().then(function (response) {
        console.log({
          response: response
        });
        _this.payment = response.payment.id;
        _this.detail = response.payment;
        _this.moreActions = _this.detail.more_actions;
        _this.customer = response.customer;
        _this.property = response.property;
        _this.billing = response.billing;
        _this.property_contact_person = response.property_contact_person;
        _this.billing_contact_person = response.billing_contact_person; // _this.contact_person = response.contact_person;

        _this.notesAttachmentData = {
          id: _this.detail.id,
          admin_remark: _this.detail.admin_note,
          client_remark: _this.detail.client_note,
          documents: _this.detail.documents
        };

        _this.$store.dispatch(SET_BREADCRUMB, [{
          title: "Payment",
          route: "payment"
        }, {
          title: "Detail"
        }, {
          title: _this.detail.barcode
        }]);
      }).catch(function () {
        _this.$router.go(-1);
      }).finally(function () {
        _this.onEscParticularField();
      });
    },
    getPayment: function getPayment() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          _this.$store.dispatch(GET, {
            url: "payment/" + _this.payment
          }).then(function (response) {
            resolve(response.data);
          }).catch(function (error) {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    }
  },
  mounted: function mounted() {
    var _this = this;

    _this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Payment",
      route: "payment"
    }, {
      title: "Detail"
    }]);

    _this.loadContent();

    _this.getAttributes(["payment_modes"]);
  },
  created: function created() {
    var _this = this;

    _this.payment = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (!_this.payment || _this.payment <= 0) {
      _this.$router.go(-1);
    }
  },
  computed: {
    canUpdatePayment: function canUpdatePayment() {
      if (this.detail && this.detail.status == 3) {
        return false;
      }

      return this.getPermission("payment:update");
    },
    PropertyDetail: function PropertyDetail() {
      var propertyArr = new Array();

      if (this.property.unit_no) {
        propertyArr.push(this.property.unit_no);
      }

      if (this.property.street_1) {
        propertyArr.push(this.property.street_1);
      }

      if (this.property.street_2) {
        propertyArr.push(this.property.street_2);
      }

      if (this.property.zip_code) {
        propertyArr.push(this.property.zip_code);
      }

      if (this.CityName) {
        propertyArr.push(this.CityName);
      }

      if (this.StateName) {
        propertyArr.push(this.StateName);
      }

      if (this.CountryName) {
        propertyArr.push(this.CountryName);
      }

      return propertyArr.join(", ");
    },
    BillingDetail: function BillingDetail() {
      var billingArr = new Array();

      if (this.billing.unit_no) {
        billingArr.push(this.billing.unit_no);
      }

      if (this.billing.street_1) {
        billingArr.push(this.billing.street_1);
      }

      if (this.billing.street_2) {
        billingArr.push(this.billing.street_2);
      }

      if (this.billing.zip_code) {
        billingArr.push(this.billing.zip_code);
      }

      if (this.CityBillingName) {
        billingArr.push(this.CityBillingName);
      }

      if (this.StateBillingName) {
        billingArr.push(this.StateBillingName);
      }

      if (this.CountryBillingName) {
        billingArr.push(this.CountryBillingName);
      }

      return billingArr.join(", ");
    },
    CityBillingName: function CityBillingName() {
      return this.lodash.isEmpty(this.billing.city) === false ? this.billing.city.name : null;
    },
    StateBillingName: function StateBillingName() {
      return this.lodash.isEmpty(this.billing.state) === false ? this.billing.state.name : null;
    },
    CountryBillingName: function CountryBillingName() {
      return this.lodash.isEmpty(this.billing.country) === false ? this.billing.country.name : null;
    },
    CityName: function CityName() {
      return this.lodash.isEmpty(this.property.city) === false ? this.property.city.name : null;
    },
    StateName: function StateName() {
      return this.lodash.isEmpty(this.property.state) === false ? this.property.state.name : null;
    },
    CountryName: function CountryName() {
      return this.lodash.isEmpty(this.property.country) === false ? this.property.country.name : null;
    }
  }
};