import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import { PATCH } from "@/core/services/store/request.module";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
export default {
  mixins: [FileManagerMixin],
  props: {
    detail: {
      required: true,
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    type: {
      required: true,
      type: String,
      default: "job"
    },
    canUpdate: {
      type: Boolean,
      default: true
    },
    isTicket: {
      type: Boolean,
      default: false
    },
    isPurchaseOrder: {
      type: Boolean,
      default: false
    },
    isProposal: {
      type: Boolean,
      default: false
    },
    isPayment: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      updateAdminDialog: false,
      updateClientDialog: false,
      formLoading: false,
      notifyAdmin: false,
      notifyCustomer: false,
      notifyEngineer: false,
      adminNotes: null,
      clientNotes: null
    };
  },
  components: {
    FileTemplate: FileTemplate,
    editor: TinyMCE
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.adminNotes = param.admin_remark;
        this.clientNotes = param.client_remark;
        this.notifyAdmin = param.notify_admin;
        this.notifyCustomer = param.notify_customer;
        this.notifyEngineer = param.notify_engineer;
      }
    }
  },
  methods: {
    updateTemplate: function updateTemplate(param) {
      if (this.canUpdate && this.getPermission(this.type + ":update")) {
        if (param == "admin") {
          this.updateAdminDialog = true;
        } else if (param == "client") {
          this.updateClientDialog = true;
        }
      }
    },
    patchDetail: function patchDetail(parent, data) {
      var _this2 = this;

      var _this = this;

      return new Promise(function (resolve, reject) {
        if (parent) {
          var APIUrl = _this2.type == "proposal" ? "proposals" : _this2.type;

          _this.$store.dispatch(PATCH, {
            url: APIUrl + "/" + parent,
            data: data
          }).then(function (_ref) {
            var data = _ref.data;
            resolve(data);
          }).catch(function (error) {
            reject(error);
          });
        } else {
          reject("Parent is required");
        }
      });
    },
    updateAdminNotes: function updateAdminNotes() {
      var _this = this;

      if (_this.canUpdate && _this.getPermission(_this.type + ":update")) {
        _this.formLoading = true;

        _this.patchDetail(_this.detail.id, {
          admin_remark: _this.adminNotes
        }).then(function () {
          _this.updateAdminDialog = false;
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.formLoading = false;
        });
      }
    },
    updateClientNotes: function updateClientNotes() {
      var _this = this;

      if (_this.canUpdate && _this.getPermission(_this.type + ":update")) {
        _this.formLoading = true;

        _this.patchDetail(_this.detail.id, {
          client_remark: _this.clientNotes
        }).then(function () {
          _this.updateClientDialog = false;
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.formLoading = false;
        });
      }
    },
    updateNotifications: function updateNotifications(query) {
      var _this = this;

      if (_this.canUpdate && _this.getPermission(_this.type + ":update")) {
        _this.formLoading = true;
        var formData = {};

        if (query == "notify_admin") {
          formData = {
            notify_admin: +_this.notifyAdmin
          };
        }

        if (query == "notify_customer") {
          formData = {
            notify_customer: +_this.notifyCustomer
          };
        }

        if (query == "notify_engineer") {
          formData = {
            notify_engineer: +_this.notifyEngineer
          };
        }

        _this.patchDetail(_this.detail.id, formData).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.formLoading = false;
        });
      }
    }
  },
  mounted: function mounted() {
    this.adminNotes = this.detail.admin_remark;
    this.clientNotes = this.detail.client_remark;
    this.notifyAdmin = this.detail.notify_admin;
    this.notifyCustomer = this.detail.notify_customer;
    this.notifyEngineer = this.detail.notify_engineer;
  }
};